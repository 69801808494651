import React from 'react';
import PropTypes from 'prop-types';
import {Card, CardMedia, CardActionArea, CardContent, Typography, makeStyles} from '@material-ui/core';
//import {translate} from "../../config/tools";

const useStyles = makeStyles(theme => ({
    campaignCard: {
        maxWidth: '255px',
        maxHeight: '425px',
        borderRadius: '12px',
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main
        },
    },
    cardMediaWrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        cursor: 'pointer',
        borderBottom: '2px dashed white',
    },
    cardMedia: {
        height: '320px',
    },
    textOverlay: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        width: '255px',
        height: '80px',
        paddingBottom: '10px',
        position: 'absolute',
        color: '#FFFFFF',
        fontWeight: 'bold',
        paddingRight: '7%',
        opacity: 1,
        background: 'transparent linear-gradient(180deg, rgba(0,40,87,0) 0%, rgba(0,40,87,0.75) 50%, rgba(0,40,87,1) 100%) 0% 0% no-repeat padding-box',
    },
    cardContent: {
        position: 'relative',
        padding: 0,
        minHeight: '103px'
    },
    leftCorner: {
        width: '19px',
        height: '19px',
        position: 'absolute',
        background: '#FFFFFF',
        borderRadius: '19px',
        top: '-10px',
        left: '-10px',
    },
    rightCorner: {
        width: '19px',
        height: '19px',
        position: 'absolute',
        background: '#FFFFFF',
        borderRadius: '19px',
        top: '-10px',
        right: '-10px',
    },
    cardContentPriceWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 16px',
    },
    cardContentFrom: {
        color: '#FFFFFF',
        opacity: 1,
        textTransform: 'uppercase',
        letterSpacing: 0.84,
        marginTop: '5px',
    },
    cardContentPrice: {
        color: '#FFFFFF',
        fontWeight: 'bold',
    },
    cardContentTextWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '16px', // to support price padding should be 0px 16px
    },
    cardContentText: {
        alignSelf: 'flex-end',
        color: '#FFFFFF',
        opacity: 0.5,
        textAlign: 'right',
    },
}));

const CampaignCard = ({title, image, description, price, href}) => {

    const classes = useStyles();

    return (
        <Card className={classes.campaignCard} elevation={0} onClick={() => window.location.href = href}>
            <CardActionArea>
                <div className={classes.cardMediaWrapper}>
                    <CardMedia
                        component={'img'}
                        className={classes.cardMedia}
                        image={image?.src}
                        title={image?.title}
                        alt={image?.title}
                    />
                    <Typography className={classes.textOverlay} variant={'h5'}>{title}</Typography>
                </div>
                <CardContent className={classes.cardContent} onClick={() => window.location.href = href}>
                    <div className={classes.leftCorner}/>
                    <div className={classes.rightCorner}/>
                    {/*<div className={classes.cardContentPriceWrapper}>
                        <Typography className={classes.cardContentFrom} variant={'button'}>
                            {translate('From')}
                        </Typography>
                        <Typography className={classes.cardContentPrice} variant={'h5'}>
                            {price}
                        </Typography>
                    </div>*/}
                    <div className={classes.cardContentTextWrapper}>
                        <Typography className={classes.cardContentText} display={"inline"} variant={'caption'}>
                            {description}
                        </Typography>
                    </div>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

CampaignCard.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    image: PropTypes.shape({
        src: PropTypes.string,
        title: PropTypes.string,
        alt: PropTypes.string,
    }),
    href: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.string,
};

CampaignCard.defaultProps = {
    title: '',
    subtitle: '',
    image: null,
    href: null,
    description: '',
    price: '€2,061',
};

export default CampaignCard;
