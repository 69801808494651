import React from 'react';
import {createMuiTheme, MuiThemeProvider, createGenerateClassName, StylesProvider} from "@material-ui/core";
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {ErrorBoundary} from "react-error-boundary";
import ErrorFallback from "./components/ErrorFallback/ErrorFallback";
import themeData from './resources/theme/theme';

const theme = createMuiTheme(themeData);

ReactDOM.render(
    <React.StrictMode>
        <StylesProvider generateClassName={createGenerateClassName({seed: 'campaign-widget'})}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <MuiThemeProvider theme={theme}>
                    <App/>
                </MuiThemeProvider>
            </ErrorBoundary>
        </StylesProvider>
    </React.StrictMode>,
    document.getElementById('campaign-widget')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
