import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    iconContainerWrapper: {
        height: '425px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconContainer: {
        height: '58px',
        width: '58px',
        borderRadius: '50%',
        opacity: 1,
        border: '1px solid #0082BB',
        cursor: 'pointer',
        color: '$white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    disabled: {
        opacity: '.3',
        cursor: 'not-allowed',
    }
}));

const Arrow = ({children, clickHandler, disabled}) => {

    const classes = useStyles();

    return (
      <div className={['azo-carousel-slide-arrow', classes.iconContainerWrapper].join(' ')}>
          <div className={[classes.iconContainer, disabled ? classes.disabled : ''].join(' ')} onClick={disabled ? () => null : clickHandler}>
              {children}
          </div>
      </div>
    );
};

Arrow.propTypes = {
    children: PropTypes.node.isRequired,
    clickHandler: PropTypes.func,
    disabled: PropTypes.bool,
};

Arrow.defaultProps = {
    clickHandler: () => null,
    disabled: false,
};

export default Arrow;
