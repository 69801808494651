import React from 'react';
import CarouselSlide from "./components/CarouselSlide/CarouselSlide";

function App() {

  return (
      <CarouselSlide cards={window.DESTINATIONS_DATA}/>
  );
}

export default App;
