import PropTypes from 'prop-types';

const Hiddable = ({hidden, children}) => {

    if (hidden) return null;

    return children;
};

Hiddable.propTypes = {
    hidden: PropTypes.bool,
    children: PropTypes.any.isRequired,
};

Hiddable.defaultProps = {
    hidden: false,
};

export default Hiddable;
