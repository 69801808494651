import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Grid, useMediaQuery, useTheme} from '@material-ui/core';
import Arrow from "./resources/Arrow";
import CampaignCard from "../CampaignCard/CampaignCard";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Hiddable from "./resources/Hiddable";
import Carousel from "nuka-carousel";
import styles from './CarouselSlide.module.scss';

const CarouselSlide = ({cards}) => {

    const {breakpoints} = useTheme();
    const isMediumDown = useMediaQuery(breakpoints.down('sm'));
    const isSmallDown = useMediaQuery(breakpoints.down('xs'));

    const [currentSlide, setCurrentSlide] = useState(0);
    const totalNumberOfCards = cards.length;
    const numberOfCardsPerSlide = !isMediumDown ? 4 : (!isSmallDown ? 3 : 1);

    return (
        <Box className={['azo-carousel-slide', styles.container].join(' ')}>
            <Grid container spacing={2}>
                <Hiddable hidden={isMediumDown}>
                    <Grid md={1} item>
                        <Arrow clickHandler={() => setCurrentSlide(slide => slide - 1)} disabled={currentSlide <= 0}>
                            <NavigateBeforeIcon color={'secondary'}/>
                        </Arrow>
                    </Grid>
                </Hiddable>
                <Grid xs={12} md={10} item>
                    <Carousel slidesToShow={numberOfCardsPerSlide}
                              renderTopCenterControls={({currentSlide}) => null}
                              renderBottomCenterControls={({currentSlide}) => null}
                              renderCenterLeftControls={({previousSlide}) => null}
                              renderCenterRightControls={({nextSlide}) => null}
                              slideIndex={currentSlide}
                              afterSlide={slide => setCurrentSlide(slide)}
                    >
                        {cards.map((card, idx) => (
                            <Box key={idx} className={['azo-carousel-slide-campaign-card', styles.slideContainer].join(' ')} component={'div'}>
                                <CampaignCard title={card.title} image={card.gridImage} href={card.link.link} description={card.slogan}/>
                            </Box>
                        ))}
                    </Carousel>
                </Grid>
                <Hiddable hidden={isMediumDown}>
                    <Grid md={1} item>
                        <Arrow clickHandler={() => setCurrentSlide(slide => slide + 1)}
                               disabled={currentSlide >= totalNumberOfCards - numberOfCardsPerSlide}>
                            <NavigateNextIcon color={'secondary'}/>
                        </Arrow>
                    </Grid>
                </Hiddable>
            </Grid>
        </Box>
    );
};

CarouselSlide.propTypes = {
    cards: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        subtitle: PropTypes.string,
        gridImage: PropTypes.shape({
            src: PropTypes.string,
            alt: PropTypes.string,
            title: PropTypes.string,
        }),
        link: PropTypes.shape({
            link: PropTypes.string,
            openInNewWindow: PropTypes.bool,
        }),
        slogan: PropTypes.string,
        nodeUrl: PropTypes.string,
        text: PropTypes.string,
        price: PropTypes.string,
    }),)
};

CarouselSlide.defaultProps = {
    cards: [],
};

export default CarouselSlide;
