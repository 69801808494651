import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styles from './ErrorFallback.module.scss';
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from '@material-ui/icons/Refresh';

const ErrorFallback = ({error: {message}, title, componentStack, resetErrorBoundary, withComponentStack}) => {

    const [reloadCount, setReloadCount] = useState(0);

    const localResetErrorBoundary = () => {
        if (reloadCount > 0) {
            window.location.reload(true);
        }

        setReloadCount(reloadCount + 1);
        resetErrorBoundary();
    }

    return (
        <div className={styles.container}>
            <div role='alert' className={styles.subContainer}>
                <Alert
                    severity='error'
                    className={styles.alert}
                    action={<IconButton onClick={localResetErrorBoundary} aria-label='reload'><RefreshIcon/></IconButton>}
                >
                    <AlertTitle>{title}</AlertTitle>
                    {message}
                </Alert>
                {withComponentStack && <pre>{componentStack}</pre>}
            </div>
        </div>
    );
};

ErrorFallback.propTypes = {
    componentStack: PropTypes.any,
    resetErrorBoundary: PropTypes.func,
    withComponentStack: PropTypes.bool,
    title: PropTypes.string,
    error: PropTypes.shape({
        message: PropTypes.string,
    }),
};

ErrorFallback.defaultProps = {
    componentStack: '-',
    resetErrorBoundary: () => null,
    withComponentStack: false,
    title: 'Error',
    error: {
        message: 'An error occurred.'
    }
};

export default ErrorFallback;